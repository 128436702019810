<template>
  <sdCards headless>
    <div id="chart">
      <VueApexCharts
        type="line"
        :series="[{ name: '', data: basicData && basicData?.length ? basicData : [] }]"
        :options="options"
        :height="350"
      />
    </div>
  </sdCards>
</template>

<script>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import VueApexCharts from 'vue3-apexcharts';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const en = require('apexcharts/dist/locales/en.json');
const ru = require('apexcharts/dist/locales/ru.json');

export default {
  components: { VueApexCharts },
  setup() {
    const i18n = reactive(useI18n());
    const route = useRoute();
    const { state } = useStore();
    const basicData = computed(() =>
      route.params.type ? state.publicAccounts.graphData : state.tradingAccounts.graphData,
    );

    const options = {
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      stroke: {
        curve: 'straight',
        width: 3,
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        x: {
          format: i18n.locale == 'ru' ? 'dd.MM.yyyy HH:mm' : 'yyyy-MM-dd. HH:MM',
        },
        fixed: {
          enabled: false,
          position: 'topRight',
        },
      },
      chart: {
        locales: [en, ru],
        defaultLocale: i18n.locale,
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        colors: ['#77B6EA', '#545454'],
        toolbar: false,
      },
    };

    return { basicData, options };
  },
};
</script>

<style>
#chart {
  /*max-height: 250px;*/
}
</style>
